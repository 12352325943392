import React, { useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useTranslation } from 'react-i18next';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Sentry from '@sentry/react';
import {
	ThemeProvider as MuiThemeProvider,
	StyledEngineProvider,
	createTheme,
} from '@mui/material/styles';

import MuiStylesProvider from '@mui/styles/StylesProvider';

import { OpenAPI as AccountOpenApi } from 'clients/AccountService';
import { OpenAPI as CoreOpenApi } from 'clients/CoreService';

import { useAutoTokenRefresh } from 'hooks/auth/useAutoTokenRefresh';
import { SnackbarProvider } from 'hooks/notistack/snackbar.provider';
import { PermissionContextProvider } from 'context/PermissionsContext/PermissionsContext';
import theme, { GlobalStyle } from 'themes';
import { AppRoutes } from 'App.routes';
import { ConfirmDialogContextProvider, ConfirmDialog } from 'components/ConfirmDialog';
import { LanguageProvider } from 'components/LanguageProvider';
import { useOfflineDialog } from 'hooks/auth/useOfflineDialog';
import { Language } from 'i18n';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import { muiUiLocales, dataGridLocales } from 'locales';

const queryClient = new QueryClient();

AccountOpenApi.BASE = process.env.REACT_APP_ACCOUNT_SERVICE_URL as string;
CoreOpenApi.BASE = process.env.REACT_APP_CORE_SERVICE_URL as string;

function App() {
	const { i18n } = useTranslation();
	const language = useMemo(() => i18n.language as Language, [i18n.language]);

	const themeWithLocale = useMemo(
		() => createTheme(theme, dataGridLocales[language], muiUiLocales[language]),
		[language],
	);

	return (
		<>
			<QueryClientProvider client={queryClient} contextSharing>
				<LocalizationProvider adapterLocale={language.toString()} dateAdapter={AdapterDayjs}>
					{/* prioritizes styled-components styled to theme provided ones */}
					<MuiStylesProvider injectFirst>
						{/* makes material theme accessible in styled-components */}
						<StyledThemeProvider theme={themeWithLocale}>
							<StyledEngineProvider injectFirst>
								<MuiThemeProvider theme={themeWithLocale}>
									<GlobalStyle />
									<ConfirmDialogContextProvider>
										<SnackbarProvider>
											<ConfirmDialog />
											<Routing />
										</SnackbarProvider>
									</ConfirmDialogContextProvider>
								</MuiThemeProvider>
							</StyledEngineProvider>
						</StyledThemeProvider>
					</MuiStylesProvider>
				</LocalizationProvider>
			</QueryClientProvider>
		</>
	);
}

function Routing() {
	useAutoTokenRefresh();
	useOfflineDialog();

	return (
		<PermissionContextProvider>
			<LanguageProvider>
				<AppRoutes />
			</LanguageProvider>
		</PermissionContextProvider>
	);
}

export default Sentry.withProfiler(App);
